import React, { memo } from 'react';
import { graphql, PageProps } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Link, Button } from 'modules';
import * as styles from './Section.module.scss';

type TypeSectionContext = {
  pathName: string;
  firstLink: string;
  secondLink: string;
  firstLinkTitle: string;
  secondLinkTitle: string;
  description: string;
  title: string;
  worksLink: string;
  video: string;
  photo: string;
};

function Section({
  pageContext,
}: PageProps<unknown, TypeSectionContext>): JSX.Element {
  const { t } = useTranslation();

  console.log(pageContext);

  return (
    <div className={styles.wrapper}>
      {/*<video*/}
      {/*  className={styles.video}*/}
      {/*  height="100%"*/}
      {/*  width="100%"*/}
      {/*  loop*/}
      {/*  muted*/}
      {/*  autoPlay*/}
      {/*  playsInline*/}
      {/*>*/}
      {/*  <source src={pageContext.photo} type="video/mp4" />*/}
      {/*</video>*/}

      <div className={styles.image}>
        <img className={styles.photo} alt="img" src={pageContext.photo} />
      </div>

      <div className={styles.section}>
        <div className={styles.section__inner}>
          <div className={styles.header}>
            <Link
              className={styles.link}
              arrowDirection="left"
              theme="white"
              to={pageContext.firstLink}
            >
              {t(pageContext.firstLinkTitle)}
            </Link>
            <h1 className={styles.title}>{t(pageContext.title)}</h1>
            <Link
              className={styles.link}
              arrowDirection="right"
              theme="white"
              to={pageContext.secondLink}
            >
              {t(pageContext.secondLinkTitle)}
            </Link>
          </div>

          <div className={styles.mobileNavigation}>
            <Link
              className={styles.link}
              arrowDirection="left"
              theme="white"
              to={pageContext.firstLink}
            >
              {t(pageContext.firstLinkTitle)}
            </Link>
            <Link
              className={styles.link}
              arrowDirection="right"
              theme="white"
              to={pageContext.secondLink}
            >
              {t(pageContext.secondLinkTitle)}
            </Link>
          </div>

          <p className={styles.description}>{t(pageContext.description)}</p>
          <Button
            className={styles.button}
            hasArrow
            size="m"
            theme="white"
            to={pageContext.worksLink}
          >
            {t('section:button')}
          </Button>
        </div>
      </div>

      {/*<div className={styles.section}>*/}
      {/*  <div className={styles.content}>*/}
      {/*    <div className={styles.content__upper}>*/}
      {/*      <div className={styles.links}>*/}
      {/*        <Link*/}
      {/*          className={styles.links__item}*/}
      {/*          theme="white"*/}
      {/*          to={pageContext.firstLink}*/}
      {/*        >*/}
      {/*          {t(pageContext.firstLinkTitle)}*/}
      {/*        </Link>*/}
      {/*        <Icon*/}
      {/*          path={separatorWhite.path}*/}
      {/*          viewBox={separatorWhite.viewBox}*/}
      {/*          className={styles.links__separator}*/}
      {/*        />*/}
      {/*        <Link*/}
      {/*          className={styles.links__item}*/}
      {/*          theme="white"*/}
      {/*          to={pageContext.secondLink}*/}
      {/*        >*/}
      {/*          {t(pageContext.secondLinkTitle)}*/}
      {/*        </Link>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <div className={styles.content__bottom}>*/}
      {/*      <div className={styles.title}>{t(pageContext.title)}</div>*/}
      {/*      <Button*/}
      {/*        className={styles.button}*/}
      {/*        hasArrow*/}
      {/*        size="m"*/}
      {/*        theme="white"*/}
      {/*        to={pageContext.worksLink}*/}
      {/*      >*/}
      {/*        {t('section:button')}*/}
      {/*      </Button>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
}

export default memo(Section);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["sections", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
