// extracted by mini-css-extract-plugin
export var button = "Section-module--button--UeuhZ";
export var description = "Section-module--description--mrBW8";
export var header = "Section-module--header--bMvVo";
export var image = "Section-module--image--B8VWk";
export var link = "Section-module--link--xHDpS";
export var mobileNavigation = "Section-module--mobileNavigation--ZZOkJ";
export var photo = "Section-module--photo--xbGy5";
export var section = "Section-module--section--Osr3h";
export var section__inner = "Section-module--section__inner--rWPF-";
export var title = "Section-module--title--2Q68O";
export var wrapper = "Section-module--wrapper--6Hlt+";